import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    target: String
  }

  connect() {
    console.log("Common Controller connected");
  }

  showPassword() {
    let current_password_input = this.currentPasswordInputTarget;
    if (current_password_input.type === "password") {
      current_password_input.type = "text";
    } else {
      current_password_input.type = "password";
    }
  }

  submitTargetForm(event) {
    const targetFormId = this.targetValue; // Get the value from the static value
    const targetForm = document.getElementById(targetFormId);

    if (targetForm) {
      console.log("##### Target Form ID: " + targetFormId);
      targetForm.submit();
    } else {
      console.error("Form not found with ID: " + targetFormId);
    }
  }

}
